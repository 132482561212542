<template>
  <SessionExpiredOverlay />
</template>

<script>
import { SessionExpiredOverlay } from '@common/components'

export default {
  components: {
    SessionExpiredOverlay,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
}
</script>
